import React, { useEffect } from "react";
import AOS from "aos";
import imgLineTop from "./assets/images/top-line.svg";
import imgLogo from "./assets/images/logo_app.svg";
import imgHeaderBanner from "./assets/images/banner-header.svg";
import imgSectionAbout from "./assets/images/img-section-about.png";
import imgSectionXChain from "./assets/images/img-section-xchain.png";
import imgMarket from "./assets/images/img-market.svg";
import imgSetting from "./assets/images/img-setting.svg";
import imgRouter from "./assets/images/img-router.svg";
import imgSecurity from "./assets/images/img-security.svg";
import iconTwitter from "./assets/images/icon-twitter.svg";
import iconTelegram from "./assets/images/icon-telegram.svg";
import iconReddit from "./assets/images/icon-reddit.svg";
import iconLogo from "./assets/images/icon-logo.svg";
import imgFrame from "./assets/images/img-frame.svg";
import iconMail from "./assets/images/icon-mail.svg";

import "aos/dist/aos.css";
import "./App.scss";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 800,
    });
  }, []);

  return (
    <div className="App">
      <header className="page-header">
        <img className="img-line-top" src={imgLineTop} alt="" />
        <nav className="header-nav">
          <img src={imgLogo} alt="" />
          <div className="header-menu">
            <a href="/">HOME</a>
            <a href="#about">ABOUT</a>

            <a href="#ecosystem">ECOSYSTEM</a>

            <a href="#docs">DOCUMENTATIONS</a>

            <a href="#resources">RESOURCES</a>
          </div>
          <div className="dropdown-list">
            <div className="btn-app">MENU</div>
            <ul className="drop-menu">
              <a href="/">HOME</a>
              <a href="#about">ABOUT</a>

              <a href="#ecosystem">ECOSYSTEM</a>

              <a href="#docs">DOCUMENTATIONS</a>

              <a href="#resources">RESOURCES</a>
            </ul>
          </div>
        </nav>
        <div className="header-banner">
          <div className="banner-left">
            <p className="banner-title">
              REDEFINING BLOCKCHAIN <br /> WITH SEXY AND VIRAL APPEAL
            </p>
            <p className="banner-content">
              Embrace a revolutionary network where sensuality meets viral
              content,
              <br /> offering a unique and captivating experience like never
              before.
            </p>
            <a className="btn-app">OPEN DAPP</a>
          </div>
          <img src={imgHeaderBanner} alt="" />
        </div>
      </header>
      <main className="page-main">
        <section className="section-about" id="about">
          <div class="image-container">
            <img src={imgLineTop} alt="" />
            <div class="centered-div">
              <a className="btn-centered">
                {/* <a href="/">ABOUT</a> */}
                ABOUT
              </a>
            </div>
          </div>
          <div data-aos="fade-up" className="container">
            <p className="title">Embracing Decentralization With X-Chain</p>
            <p className="content">
              X-Chain empowers a wide array of alluring decentralized services,
              pushing the boundaries of what is possible without the constraints
              of centralization. In a seductive ecosystem, explore various
              services, from finance and gaming to social networking and more.
              Unleash your desires, connect with like-minded individuals, and
              embrace the liberating allure of decentralization.
            </p>
            <div className="btn-actions">
              <a className="btn-app">LEARN MORE</a>
              <a className="btn-app">EXPLORE TOKENS</a>
            </div>
            <div className="img-about">
              <img src={imgSectionAbout} alt="" />
            </div>
          </div>
        </section>
        <section className="section-xchain-ecosystem" id="ecosystem">
          <div class="image-container">
            <img src={imgLineTop} alt="" />
            <div class="centered-div">
              <a className="btn-centered">
                XCHAIN
                <br /> ECOSYSTEM
              </a>
            </div>
          </div>
          <div className="section-wrapper">
            <div data-aos="fade-up" className="section-top">
              <p className="title">The Universe Of Glamorous Services</p>
              <p className="content">
                A complete ecosystem where you can explore the invincible nature
                of decentralization and <br /> the alluring temptation.
              </p>
            </div>
            <div data-aos="fade-up" className="container">
              <div className="img-xchain">
                <img src={imgSectionXChain} alt="" />
              </div>
              <div className="list-service">
                <div className="service">
                  <img src={imgMarket} alt="" />
                  <div className="content-wrapper">
                    <p className="title">Marketplace</p>
                    <p className="content">
                      A decentralized marketplace where users can trade and buy
                      products and services using $XCHAIN with very low fees and
                      instant transaction confirmation.
                    </p>
                  </div>
                </div>
                <div className="service">
                  <img src={imgSetting} alt="" />
                  <div className="content-wrapper">
                    <p className="title">Marketplace</p>
                    <p className="content">
                      A decentralized marketplace where users can trade and buy
                      products and services using $XCHAIN with very low fees and
                      instant transaction confirmation.
                    </p>
                  </div>
                </div>
                <div className="service">
                  <img src={imgRouter} alt="" />
                  <div className="content-wrapper">
                    <p className="title">Marketplace</p>
                    <p className="content">
                      A decentralized marketplace where users can trade and buy
                      products and services using $XCHAIN with very low fees and
                      instant transaction confirmation.
                    </p>
                  </div>
                </div>
                <div className="service">
                  <img src={imgSecurity} alt="" />
                  <div className="content-wrapper">
                    <p className="title">Marketplace</p>
                    <p className="content">
                      A decentralized marketplace where users can trade and buy
                      products and services using $XCHAIN with very low fees and
                      instant transaction confirmation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-road-map" id="roadmap">
          <div class="image-container">
            <img src={imgLineTop} alt="" />
            <div class="centered-div">
              <div className="btn-centered">
                <a href="/">ROADMAP</a>
              </div>
            </div>
          </div>
          <div data-aos="fade-up" className="container">
            <div className="list-phase">
              <div className="phase">
                <p className="title">PHASE 1:</p>
                <p className="sub-title">Foundation</p>
                <ul className="content">
                  <li>- Initial idea and concept development.</li>
                  <li>- Assembling the core team.</li>
                  <li>- Conduct pre-sale and secure fundraising.</li>
                  <li>
                    - Build X-Chain blockchain with security and scalability.
                  </li>
                  <li>- Launch $XCHAIN as the native token.</li>
                </ul>
              </div>
              <div className="phase">
                <p className="title">PHASE 2:</p>
                <p className="sub-title">
                  Community <br />
                  Engagement
                </p>
                <ul className="content">
                  <li>- Community building.</li>
                  <li>- Release testnet with basic features.</li>
                  <li>- Start marketing campaigns.</li>
                  <li>- Cultivate a supportive community.</li>
                  <li>
                    - Collaborate with influencers to interact with users.
                  </li>
                </ul>
              </div>
              <div className="phase">
                <p className="title">PHASE 3:</p>
                <p className="sub-title">
                  Sexy Blockchain
                  <br />
                  Platform
                </p>
                <ul className="content">
                  <li>- Initial idea and concept development.</li>
                  <li>- Assembling the core team.</li>
                  <li>- Conduct pre-sale and secure fundraising.</li>
                  <li>
                    - Build X-Chain blockchain with security and scalability.
                  </li>
                  <li>- Launch $XCHAIN as the native token.</li>
                </ul>
              </div>
              <div className="phase">
                <p className="title">PHASE 4:</p>
                <p className="sub-title">Global Reach</p>
                <ul className="content">
                  <li>- Initial idea and concept development.</li>
                  <li>- Assembling the core team.</li>
                  <li>- Conduct pre-sale and secure fundraising.</li>
                  <li>
                    - Build X-Chain blockchain with security and scalability.
                  </li>
                  <li>- Launch $XCHAIN as the native token.</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="section-community">
          <div class="image-container">
            <img src={imgLineTop} alt="" />
            <div class="centered-div">
              <div className="btn-centered">
                <a href="/">COMMUNITY</a>
              </div>
            </div>
          </div>
          <div data-aos="fade-up" className="container">
            <div className="section-left">
              <p className="title">Meet The Sensual Community.</p>
              <p className="content">
                Join a fast-growing community of developers and innovators
                connected <br />
                all over the world, building a new era of the sensual.
              </p>
              <div className="btn-app">JOIN NOW</div>
            </div>
            <div className="section-right">
              <div className="list-media">
                <div className="media-item">
                  <img className="img-frame" src={imgFrame} alt="" />
                  <p className="content">
                    <img className="icon-media" src={iconTelegram} alt="" />
                    Ask general questions and chat with the worldwide community
                    on Telegram.
                  </p>
                </div>
                <div className="media-item">
                  <img src={imgFrame} alt="" />
                  <p className="content">
                    <img className="icon-media" src={iconTwitter} alt="" />
                    Ask general questions and chat with the worldwide community
                    on Telegram.
                  </p>
                </div>
                <div className="media-item">
                  <img src={imgFrame} alt="" />
                  <p className="content">
                    <img className="icon-media" src={iconReddit} alt="" />
                    Ask general questions and chat with the worldwide community
                    on Telegram.
                  </p>
                </div>
                <div className="media-item">
                  <img src={imgFrame} alt="" />
                  <p className="content">
                    <img className="icon-media" src={iconLogo} alt="" />
                    Ask general questions and chat with the worldwide community
                    on Telegram.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-mail">
          <div data-aos="fade-up" className="container">
            <p className="title">Become A Part Of Us</p>
            <div className="mail-box">
              <img src={iconMail} alt="" />
              <input type="email" />
              <div className="btn-send">SEND EMAIL</div>
            </div>
          </div>
        </section>
      </main>
      <footer className="page-footer">
        <img src={imgLineTop} alt="" />
        <div data-aos="fade-up" className="container">
          <div className="content-left">
            <img src={imgLogo} alt="" />
          </div>
          <div className="content-right">
            <div className="content-col">
              <p>HOME</p>
            </div>
            <div className="content-col">
              <p>ABOUT</p>
              <ul>
                <li>
                  <a href="">Resources</a>
                </li>
                <li>
                  <a href="">Documentations</a>
                </li>
              </ul>
            </div>
            <div className="content-col">
              <p>ECOSYSTEM</p>
              <ul>
                <li>
                  <a href="">Faucet</a>
                </li>
                <li>
                  <a href="">Bridge</a>
                </li>
                <li>
                  <a href="">Swap</a>
                </li>
                <li>
                  <a href="">Explore</a>
                </li>
              </ul>
            </div>
            <div className="content-col">
              <p>SOCIAL MEDIA</p>
              <ul>
                <li>
                  <a
                    href="https://t.me/XChainTG"
                    target="_blank"
                    rel="noopener noreferrer">
                    Telegram
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/XChain_X"
                    target="_blank"
                    rel="noopener noreferrer">
                    Twitter
                  </a>
                </li>
                {/* <li>
                  <a href="">Discord</a>
                </li> */}
                {/* <li>
                  <a href="">Medium</a>
                </li> */}
                <li>
                  <a href="">Github</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <p className="footer-copyright">
          Redefining Blockchain With Sexy And Viral Appeal
          <br />
          <span className="copyright">© X-chain 2023 - All right reserved</span>
        </p>
      </footer>
    </div>
  );
}

export default App;
